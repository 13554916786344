<template>
  <div>
    <transition name="head">
      <header class="flex between" v-show="headShow">
        <router-link to="/">
          <div class="flex" style="align-items: flex-end">
            <!-- <img src="../assets/imgs/logo.png" alt="" />
          <div class="leftShu"></div>
          <h2 style="line-height: 1">创变营销</h2> -->
            <img class="titleTop" src="../assets/imgs/topTitle.png" alt="" />
          </div>
        </router-link>

<!--        <ul class="flex">-->
<!--          <li @click="activeIndex = 0,headShow=true" :class="{ active: activeIndex == 0 }">-->
<!--            总览-->
<!--          </li>-->
<!--          <li-->
<!--            v-show="show1"-->
<!--            @click="activeIndex = 1,headShow=false"-->
<!--            :class="{ active: activeIndex == 1 }"-->
<!--          >-->
<!--            投放营销-->
<!--          </li>-->
<!--          <li-->
<!--            v-show="show2"-->
<!--            @click="activeIndex = 2,headShow=false"-->
<!--            :class="{ active: activeIndex == 2 }"-->
<!--          >-->
<!--            邮箱营销-->
<!--          </li>-->
<!--          <li-->
<!--            v-show="show3"-->
<!--            @click="activeIndex = 3,headShow=false"-->
<!--            :class="{ active: activeIndex == 3 }"-->
<!--          >-->
<!--            新媒体营销-->
<!--          </li>-->
<!--          <li-->
<!--            v-show="show4"-->
<!--            @click="activeIndex = 4,headShow=false"-->
<!--            :class="{ active: activeIndex == 4 }"-->
<!--          >-->
<!--            营销魔盒-->
<!--          </li>-->
<!--        </ul>-->

        <div class="flex">
          <el-dropdown @command="handleCommand">
            <span
              class="el-dropdown-link avatar"
              @mouseover="wx = false"
              @mouseout="wx = false"
            >
              <img :src="userinfo.avatar" alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @mouseover="wx = true">
                  <img v-show="wx" id="wx" src="../assets/imgs/wx.png" alt="" />
                  <i class="el-icon-headset"></i>
                  专属客服
                </div>
              </el-dropdown-item>
              <el-dropdown-item command="修改密码">
                <div @mouseout="wx = false">
                  <i class="el-icon-edit"></i>
                  <span>修改密码</span>
                </div>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
            <div @mouseout="wx = false">
              <i class="el-icon-delete"></i>
              <span>清除缓存</span>
            </div>
          </el-dropdown-item> -->
              <el-dropdown-item command="退出登录">
                <div @mouseout="wx = false">
                  <i class="el-icon-switch-button"></i>
                  <span>退出登录</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div style="margin-left: 10px">
            <p class="f_weight">{{ userinfo.nickname }}</p>
            <p>{{ userinfo.mobile }}</p>
          </div>
        </div>
      </header>
    </transition>
    <i v-show="activeIndex !== 0 && activeIndex !== 5" class="el-icon-more" @click="pull"></i>
    <main style="display: flex" v-show="activeIndex == 0">
<!--      <div class="left_menu">-->

<!--        <div :class="item.unique == activeMenu ? 'item menu_active':'item'" @click="clickMenu(item.unique, index)" v-for="(item,index) in menus" :key="index">-->
<!--          <img v-if="item.unique == activeMenu" :src="item.img1" alt="">-->
<!--          <img v-else :src="item.img" alt="">-->
<!--          <span>{{item.title}}</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="right_content">
        <div class="flex between" style="margin: 30px 0">
<!--          <div class="info">-->
<!--            <div class="flex between top">-->
<!--              <div>-->
<!--                <i class="el-icon-user-solid"></i>-->
<!--                账户财务-->
<!--              </div>-->
<!--              <button type="button" @click="recharge = true">充值</button>-->
<!--            </div>-->
<!--            <p>-->
<!--              账户余额:<span style="color: red">{{ balance }}元</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              累计消耗:<span>{{ consumptionAmount }}元</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              投放消耗:<span>{{ tfAmount }}元</span>邮箱消耗:<span-->
<!--            >{{ yxAmount }}元</span-->
<!--            >新媒体消耗:<span>{{ xmtAmount }}元</span>-->
<!--            </p>-->
<!--          </div>-->
          <div class="info">
            <div class="block">
<!--              <span class="demonstration">默认 Hover 指示器触发</span>-->
              <el-carousel style="width: 100%;height: 280px;border-radius: 10px;" height="280px">
                <el-carousel-item v-for="item in banner" :key="item.id">
                  <img :src="item.img" alt="" style="width: 100%;height: 280px">
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class="message">
            <div class="flex between top price-info">
              <div class="label">
                账户余额 (元)
              </div>
              <div class="price">
                {{balance}}
              </div>
              <div>
                <el-button class="chongzhi" type="primary" @click="recharge = true">立即充值</el-button>
                <el-button class="jiaoyilog"  @click="dealLogFun(1)">交易明细</el-button>
              </div>
            </div>
<!--            <div class="flex between top">-->
<!--              <div>-->
<!--                <i class="el-icon-message-solid"></i>-->
<!--                消息通知-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="messageList.length">-->
<!--              <div style="height: 175px">-->
<!--                <div-->
<!--                    v-for="o in messageList"-->
<!--                    :key="o.id"-->
<!--                    class="item flex between"-->
<!--                >-->
<!--                  <p class="messageText">-->
<!--                    <i-->
<!--                        style="font-size: 18px; vertical-align: middle"-->
<!--                        class="el-icon-chat-dot-square"-->
<!--                    ></i>-->
<!--                    【系统消息】{{ o.detail }}-->
<!--                  </p>-->
<!--                  <span>{{ o.create_time }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--              <el-pagination-->
<!--                  @size-change="handleSizeChange"-->
<!--                  @current-change="handleCurrentChange"-->
<!--                  :current-page="currentPage"-->
<!--                  :page-size="pageSize"-->
<!--                  background-->
<!--                  layout="prev, pager, next"-->
<!--                  :total="total"-->
<!--              >-->
<!--              </el-pagination>-->
<!--            </div>-->
<!--            <div v-else>-->
<!--              <el-empty :image-size="80" description="暂无消息"></el-empty>-->
<!--            </div>-->
          </div>
        </div>


        <div class="tongji">
          <div class="item">
            <div class="left">
              <img src="../assets/imgs/countprice.png" alt="">
            </div>
            <div class="right">
              <div class="label">总消费金额</div>
              <div class="val" style="color: #E99E5B">{{moneyCount.day}}</div>
              <div class="yesterday-val"><span>昨日 </span>{{moneyCount.yesterday}}</div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/imgs/neirong.png" alt="">
            </div>
            <div class="right">
              <div class="label">AI内容生产量</div>
              <div class="val" style="color: #E46388">{{content.day}}</div>
              <div class="yesterday-val"><span>昨日 </span>{{content.yesterday}}</div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/imgs/toufang.png" alt="">
            </div>
            <div class="right">
              <div class="label">投放展示量</div>
              <div class="val" style="color: #6778ED">{{toufang.day}}</div>
              <div class="yesterday-val"><span>昨日 </span>{{toufang.yesterday}}</div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/imgs/meitiyuedu.png" alt="">
            </div>
            <div class="right">
              <div class="label">新媒体内容发布量</div>
              <div class="val" style="color: #77C7E9">{{ AIcount.day }}</div>
              <div class="yesterday-val"><span>昨日 </span>{{ AIcount.yesterday }}</div>
            </div>
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/imgs/email.png" alt="">
            </div>
            <div class="right">
              <div class="label">邮件送达量</div>
              <div class="val" style="color: #63C29E">{{emailCount.day}}</div>
              <div class="yesterday-val"><span>昨日 </span>{{emailCount.yesterday}}</div>
            </div>
          </div>
        </div>

        <div class="flex title">
          <img src="../assets/imgs/background/title.png" alt="" />
          <h3>热门产品推荐</h3>
        </div>
        <div class="flex between project">
          <div class="item" v-for="(item, index) in productList" :key="item.id">
            <img :src="item.logo" alt="" />
            <div style="padding: 10px">
              <p class="title">{{ item.title }}</p>
              <p style="height: 40px">{{ item.desc }}</p>
              <div class="flex between end">
                <p>￥{{ item.price }}</p>
                <button ref="freeBtn" :id="'freeBtn'+item.id" @click="tryOut(item.id, index)" type="button">
                  免费试用
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
<!--    <iframe frameborder="0" v-show="activeIndex == 4" :src="mboxUrl"></iframe>-->
<!--    <iframe v-show="activeIndex == 1" :src="adUrl" frameborder="0"></iframe>-->
<!--    <iframe v-show="activeIndex == 2" :src="edmUrl" frameborder="0"></iframe>-->
<!--    <iframe-->
<!--      v-show="activeIndex == 3"-->
<!--      :src="newContentUrl"-->
<!--      frameborder="0"-->
<!--    ></iframe>-->
    <main v-show="activeIndex == 5" class="modification">
      <div>
        <h2>修改密码</h2>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item prop="oldpassword">
            <el-input
              placeholder="请输入原密码"
              prefix-icon="el-icon-lock"
              v-model="ruleForm.oldpassword"
            ></el-input>
          </el-form-item>
          <el-form-item prop="newpassword">
            <el-input
              placeholder="请输入密码"
              show-password
              prefix-icon="el-icon-lock"
              v-model="ruleForm.newpassword"
            ></el-input>
          </el-form-item>
          <el-form-item prop="affirmNewpassword">
            <el-input
              placeholder="请输入密码"
              show-password
              prefix-icon="el-icon-lock"
              v-model="ruleForm.affirmNewpassword"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <button type="button" class="login" @click="save('ruleForm')">
              保存
            </button>
          </el-form-item>
        </el-form>
      </div>
    </main>

    <el-dialog title="充值" :visible.sync="recharge">
      <div class="box">
        <div class="Boxtop">在线支付</div>
        <div class="type">
          <p>充值方式</p>
          <div>
            <svg
              t="1657523094848"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3744"
              width="40"
              height="40"
            >
              <path
                d="M395.846 603.585c-3.921 1.98-7.936 2.925-12.81 2.925-10.9 0-19.791-5.85-24.764-14.625l-2.006-3.864-78.106-167.913c-0.956-1.98-0.956-3.865-0.956-5.845 0-7.83 5.928-13.68 13.863-13.68 2.965 0 5.928 0.944 8.893 2.924l91.965 64.43c6.884 3.864 14.82 6.79 23.708 6.79 4.972 0 9.85-0.945 14.822-2.926L861.71 282.479c-77.149-89.804-204.684-148.384-349.135-148.384-235.371 0-427.242 157.158-427.242 351.294 0 105.368 57.361 201.017 147.323 265.447 6.88 4.905 11.852 13.68 11.852 22.45 0 2.925-0.957 5.85-2.006 8.775-6.881 26.318-18.831 69.334-18.831 71.223-0.958 2.92-2.013 6.79-2.013 10.75 0 7.83 5.929 13.68 13.865 13.68 2.963 0 5.928-0.944 7.935-2.925l92.922-53.674c6.885-3.87 14.82-6.794 22.756-6.794 3.916 0 8.889 0.944 12.81 1.98 43.496 12.644 91.012 19.53 139.48 19.53 235.372 0 427.24-157.158 427.24-351.294 0-58.58-17.78-114.143-48.467-163.003l-491.39 280.07-2.963 1.98z"
                fill="#09BB07"
                p-id="3745"
              ></path>
            </svg>
            微信支付
            <div class="true">
              <span>✓</span>
            </div>
          </div>
        </div>
        <div class="sum">
          <p>充值金额</p>
          <ul>
            <li
              v-for="(item, index) in arr"
              :key="item"
              @click="findIndex = index"
            >
              <span>{{ item }}</span>
              <div :class="{ li_active: index === findIndex }">
                <span>✓</span>
              </div>
            </li>
          </ul>
        </div>
        <input
          @focus="customfocus"
          @blur="customblur"
          v-model="money"
          type="text"
          name=""
          class="custom"
          placeholder="自定义金额，最低0.01元起"
        />
        <button id="gorecharge" @click="goRecharge">去充值</button>
      </div>
    </el-dialog>
    <el-dialog
        title="交易明细"
        :visible.sync="dialogVisible"
        width="30%">
      <el-table
          :data="dealLog.list"
          border
          style="width: 100%">
        <el-table-column
            prop="createTime"
            label="日期"
            width="180">
        </el-table-column>
        <el-table-column
            prop="type"
            label="订单类型"
            width="180">
        </el-table-column>
        <el-table-column
            prop="orderId"
            label="订单号"
            width="180">
        </el-table-column>
        <el-table-column
            prop="amount"
            label="订单金额">
        </el-table-column>
        <el-table-column
            prop="title"
            label="订单描述">
        </el-table-column>
      </el-table>
      <el-pagination style="text-align: right;
    position: relative;
    top: 20px;"
          background
                     :page="dealPage"
         :page-size="dealPageSize"
          layout="prev, pager, next"
         @current-change="dealLogFun"
          :total="dealLog.total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getShowDataApi,
  Product,
  Message,
  updatePassword,
  loginout,
  userAmountDetails,
  pay,
  query,
  autoRegisterLogin,
  mboxAutoRegisterLogin,
  contetnCount,
  dealLogApi,
  writeHistoryTotal,
  emailCountApi
} from "../http/api";
export default {
  name: "background",
  data() {
    return {
      activeMenu:0, // 选中的菜单下标
      banner:[
        {
          img:require('../assets/imgs/banner1.png'),
        },
        {
          img:require('../assets/imgs/banner2.png'),
        },

      ],
      // menus: [
      //
      //
      // ],
      headShow:true,
      userinfo: JSON.parse(localStorage.getItem("userinfo")) || {},
      platform: localStorage.getItem("platform").split(","),
      adUrl: "",
      edmUrl: "",
      mboxUrl: "",
      newContentUrl: "",
      contentUUid: "",
      contentUserName: "",
      contentUrl: this.$route.query.contentUrl,
      newWindow: null,
      money: "",
      orderNumber: "",
      balance: 0,
      consumptionAmount: 0,
      tfAmount: 0,
      xmtAmount: 0,
      yxAmount: 0,
      arr: ["100", "200", "500", "1000", "2000"],
      activeIndex: 0,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      wx: false,
      // 当前页数
      currentPage: 1,
      // 每页显示的数量
      pageSize: 5,
      total: "",
      messageList: [],
      productList: [],
      findIndex: 0,
      recharge: false,
      ruleForm: {
        oldpassword: "",
        newpassword: "",
        affirmNewpassword: "",
      },
      rules: {
        oldpassword: [
          { required: true, message: "请输入原密码", trigger: "change" },
        ],
        newpassword: [
          { required: true, message: "请输入新密码", trigger: "change" },
        ],
        affirmNewpassword: [
          { required: true, message: "请确认新密码", trigger: "change" },
        ],
      },
      content:{
        day:0,
        yesterday:0
      },
      toufang:{
        day:0,
        yesterday:0
      },
      moneyCount:{
        day:0,
        yesterday:0
      },
      AIcount:{
        day:0,
        yesterday:0
      },
      emailCount:{
        day:0,
        yesterday:0
      },

      dealPage:1,
      dealPageSize:5,
      dealLog:[], // 充值记录
      dialogVisible: false,
    };
  },
  methods: {
    // 交易记录
    dealLogFun(e) {
      console.log('dealLogFun',e)

      var t = this
      t.dealPage = e
      t.dialogVisible = true
      dealLogApi({
        page:t.dealPage,
        pageSize:t.dealPageSize,
        userIdentifies: localStorage.getItem('id'),
      }).then((res) => {
        console.log('交易记录',res);
        t.dealLog = res.data
        for (let i = 0; i < t.dealLog.list.length; i++) {
          if(t.dealLog.list[i].type == 1){
            t.dealLog.list[i].type = '消费'
          }else{
            t.dealLog.list[i].type = '充值'
          }
        }
      })
    },
    // 统计
    count() {
      var t = this
      // 投放展示
      getShowDataApi({
        id:localStorage.getItem('id')
      }).then((res) => {
        console.log('投放展示',res);
        t.toufang.day = res.all
        t.toufang.yesterday = res.yesterday || 0
      })
      // AI统计
      writeHistoryTotal({
        identity: localStorage.getItem('id')
      }).then((res) => {
        console.log('AI统计',res);
        t.content.day = res.data.total
        t.content.yesterday = res.data.yesterdayTotal
      })
      // 邮箱统计
      emailCountApi({
        uniqueId: localStorage.getItem('id')
      }).then((res) => {
        console.log('邮箱统计',res);
        if(res.code !== -1){
          t.emailCount.day = res.data.total
          t.emailCount.yesterday = res.data.yesterdayTotal
        }
      })

      // 内容统计
      contetnCount({
        id:localStorage.getItem('id')
      }).then(res => {
        console.log('内容统计',res);
        t.AIcount.day = res.data.day
        t.AIcount.yesterday = res.data.yesterday
      })

    },
    clickMenu(unique, index){
      this.activeMenu = unique
      this.tryOut(unique, index)
    },

    // mbox自动登录
    autoMbox() {
      // this.activeIndex = 4;
      var data = {
        uniqueId: localStorage.getItem("id"),
        username: JSON.parse(localStorage.getItem("userinfo")).nickname,
        phone: JSON.parse(localStorage.getItem("userinfo")).mobile,
      };

      mboxAutoRegisterLogin(data).then((res) => {
        // this.mboxUrl = 'http://localhost:9528/#/creation?token=' + res.data.token
        this.mboxUrl =
          "https://w.mbox.91s2b2c.com/#/creation?token=" + res.data.token;
        window.open(this.mboxUrl)
      });
    },
    // 分发平台自动登录

    autoRegisterLoginFn() {
      this.contentUUid = this.queryURLparamsRegEs5(this.platform[2]).uuid;
      this.contentUserName = this.queryURLparamsRegEs5(
        this.platform[2]
      ).username;
      var params = {
        uuid: this.contentUUid,
        username: this.contentUserName,
      };
      // 为了区分 分发平台的url里有token的情况 多传个uuid 来判断
      autoRegisterLogin(params).then((res) => {
        this.newContentUrl =
          "https://aict.91s2b2c.com/main.html?token=" +
          // "http://127.0.0.1:5500/distribute/xiaoer/WebContent/main.html?token=" +
          res.data.accessToken + '&uuid=' + this.contentUUid;
        window.open(this.newContentUrl);
      });
    },
    // 自定义金额 聚焦
    customfocus() {
      this.findIndex = 5;
    },
    // 自定义金额 失焦
    customblur() {
      if (this.money) {
        this.findIndex = 5;
      } else {
        this.findIndex = 0;
      }
    },
    addMenus(obj){
      var menus = JSON.parse(localStorage.getItem("menus"));
      var has = false
      for (let i = 0; i < menus.length; i++) {
        if(menus[i].unique == obj.unique){
          has = true
        }
      }
      if(has == false){
        menus.push(obj)
        localStorage.setItem('menus', JSON.stringify(menus));
        this.menus = menus;
      }
    },
    tryOut(id, index) {
      // this.headShow = false
      var product = JSON.parse(localStorage.getItem('shiyongStatus'));
      product.push(id);
      localStorage.setItem('shiyongStatus', JSON.stringify(product))

      if (id == 1) {
        // this.activeIndex = 1;
        this.adUrl = this.platform[0];

        // this.adUrl = 'http://192.168.8.43:8888/admanage.html?uniqueId=7&username=叮叮当'
        localStorage.setItem(localStorage.getItem("id") + "adProduct", true);
        window.open(this.adUrl);
        // this.addMenus({
        //   unique:1,
        //   img: require('../assets/imgs/guanggaotoufang.png'),
        //   img1: require('../assets/imgs/guanggaotoufang1.png'),
        //   title:'广告投放'
        // })
      } else if (id == 2) {
        // this.activeIndex = 2;
        this.edmUrl = this.platform[1];
        // this.edmUrl = "https://edm-marketing.91s2b2c.com/generalize-mailOverview?uniqueId=发热&username=null&companyName=第一次扫码登录"
        localStorage.setItem(localStorage.getItem("id") + "edmProduct", true);
        // this.edmUrl = 'http://localhost:9528/generalize-mailOverview?uniqueId=5&username=5&companyName=';
        window.open(this.edmUrl);
        // this.addMenus({
        //   unique:2,
        //   img: require('../assets/imgs/youxiangtuiguang.png'),
        //   img1: require('../assets/imgs/youxiangtuiguang1.png'),
        //   title:'邮箱推广'
        // })
      } else if (id == 3) {
        // this.activeIndex = 3;
        localStorage.setItem(
          localStorage.getItem("id") + "contentProduct",
          true
        );
        this.autoRegisterLoginFn();
        // this.addMenus({
        //   unique:3,
        //   img: require('../assets/imgs/xinmeiti.png'),
        //   img1: require('../assets/imgs/xinmeiti1.png'),
        //   title:'新媒体引流'
        // })
      } else if (id == 4) {
        // this.activeIndex = 4;
        localStorage.setItem(localStorage.getItem("id") + "mboxProduct", true);
        // this.addMenus({
        //   unique:4,
        //   img: require('../assets/imgs/ai.png'),
        //   img1: require('../assets/imgs/ai11.png'),
        //   title:'营销魔盒'
        // })
        this.autoMbox();
      }
      this.freeStyle(index);
    },
    // 每页显示条数改变
    handleSizeChange(val) {
      this.pageSize = val;
      this.getMessage(); //请求后端接口数据的方法
    },
    // 翻页上一页/下一页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMessage(); //请求后端接口数据的方法
    },

    handleCommand(command) {
      if (command == "修改密码") {
        this.activeIndex = 5;
      } else if (command == "退出登录") {
        loginout().then((res) => {
          if (res.code == 0) {
            localStorage.removeItem("token");
            localStorage.removeItem("userinfo");
            this.$message.success({
              duration: 2000,
              message: "退出成功",
            });

            this.$router.replace({
              name: "login",
            });
          } else {
            this.$message.error({
              duration: 2000,
              message: res.message,
            });
          }
        });
      }
    },
    // 修改密码
    save(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          if (this.ruleForm.newpassword == this.ruleForm.affirmNewpassword) {
            let data = {
              y_password: this.ruleForm.oldpassword,
              password: this.ruleForm.newpassword,
              password_confirm: this.ruleForm.affirmNewpassword,
            };
            updatePassword(data).then((res) => {
              if (res.code == 0) {
                this.$message.success({
                  duration: 2000,
                  message: "修改成功",
                });
                this.activeIndex = 0;
              } else {
                this.$message.error({
                  duration: 2000,
                  message: res.message,
                });
              }
            });
          } else {
            this.$message.error({
              duration: 2000,
              message: "两次密码不一致",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 充值
    goRecharge() {
      var amount;
      if (this.arr[this.findIndex]) {
        amount = this.arr[this.findIndex];
      } else {
        amount = this.money;
      }
      if (amount < 0.01) {
        this.$message.error({
          duration: 2000,
          message: "金额不得小于0.01元",
        });
        return false;
      }
      var data = {
        userIdentifies: localStorage.getItem("id"),
        platId: 8,
        title: "聚合平台充值",
        amount,
        payType: 3,
      };
      pay(data).then((res) => {
        var data = JSON.parse(res.data);
        if (res.code == 0) {
          this.open();
          this.orderNumber = data.orderNumber;
          this.newWindow = window.open(
            "/pay" +
              `?img=${data.payImg}&orderNumber=${data.orderNumber}&money=${amount}`,
            "pay"
          );
        } else {
          this.$message.error({
            duration: 2000,
            message: "系统异常",
          });
        }
      });
    },
    open() {
      this.$confirm("请在新窗口完成充值!", "余额充值", {
        confirmButtonText: "已完成充值",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          console.log(action);
          if (action === "confirm") {
            query({ orderNo: this.orderNumber }).then((res) => {
              if (res.code == 0) {
                done();
                this.$message.success({
                  duration: 2000,
                  message: "支付成功!",
                });
                this.recharge = false;
                this.getBalance();
                this.message();
              } else {
                this.$message.error({
                  duration: 2000,
                  message: "未支付成功!",
                });
              }
            });
          } else {
            if (this.newWindow) {
              this.newWindow.close();
              done();
              this.$message.warning({
                duration: 2000,
                message: "取消支付!",
              });
            }
          }
        },
      });
    },
    getMessage() {
      //当前页
      var page = this.currentPage; //请求所需的参数 根据后端接口要求来
      //每页显示条数
      var pageSize = this.pageSize; //请求所需的参数 根据后端接口要求来
      //接口
      Message({ page, pageSize }).then((res) => {
        if (res.data) {
          //赋值
          this.messageList = res.data.data;
          this.total = res.data.total;
          this.currentPage = res.data.current_page;
        }
      });
    },
    getBalance() {
      var data = {
        userIdentifies: localStorage.getItem("id"),
        platId: 8,
      };
      userAmountDetails(data).then((res) => {
        this.consumptionAmount = res.data.consumptionAmount;
        this.balance = res.data.balance;
        this.tfAmount = res.data.tfAmount;
        this.yxAmount = res.data.yxAmount;
        this.xmtAmount = res.data.xmtAmount;
        this.moneyCount.day = res.data.consumptionAmount;
        this.moneyCount.yesterday = res.data.yesterdayAmount;
      });
    },
    queryURLparamsRegEs5(url) {
      let obj = {};
      let reg = /([^?=&]+)=([^?=&]+)/g;
      url.replace(reg, function () {
        obj[arguments[1]] = arguments[2];
      });
      return obj;
    },
    freeStyle() {
      this.$nextTick(() => {
        var product = JSON.parse(localStorage.getItem('shiyongStatus'));
        for (let i = 0; i < product.length; i++) {
          var label = document.getElementById('freeBtn'+product[i]);
          label.innerText = "正在试用";
          label.style.background = "orange";
        }
      });
    },
    pull(){
      if(this.headShow){
        this.headShow = false;
      }else{
        this.headShow = true;
      }

    }
  },
  created() {
    if (this.$route.params.index) {
      this.$nextTick(() => {
        this.activeIndex = 5;
      });
    }

  },
  mounted() {
    this.count()
    // this.menus = JSON.parse(localStorage.getItem('menus'))

    // console.log('this.menus',this.menus);
    // if(!this.menus || this.menus.length <= 0){
    //   this.menus = [
    //     {
    //       unique:0,
    //       img: require('../assets/imgs/zhuanghuzonglan.png'),
    //       img1: require('../assets/imgs/zhuanghuzonglan1.png'),
    //       title:'账户总览'
    //     },
    //   ]
    //   localStorage.setItem('menus',JSON.stringify(this.menus))
    // }

    var shiyongzhuangtai = JSON.parse(localStorage.getItem('shiyongStatus'))
    if(!shiyongzhuangtai || shiyongzhuangtai.length <= 0){
      localStorage.setItem('shiyongStatus', JSON.stringify([]))
    }
    if (localStorage.getItem(localStorage.getItem("id") + "adProduct")) {
      this.adUrl = this.platform[0];
      // this.show1 = true;
    }
    if (localStorage.getItem(localStorage.getItem("id") + "edmProduct")) {
      this.edmUrl = this.platform[1];
      // this.show2 = true;
    }
    if (localStorage.getItem(localStorage.getItem("id") + "contentProduct")) {
      // this.autoRegisterLoginFn();
      // this.show3 = true;
    }
    if (localStorage.getItem(localStorage.getItem("id") + "mboxProduct")) {
      // this.autoMbox();
      // this.show4 = true;
    }
    this.activeIndex = 0;
    this.getMessage();
    Product().then((res) => {
      this.productList = res.data;
      this.freeStyle()
    });
    this.getBalance();

  },
};
</script>

<style lang="scss" scoped>
.menu_active{
  background: #3B8DDF;
  color: #ffffff;
}
.tongji{
  display: flex;
  justify-content: space-between;
  .item{
    height: 120px;
    width: 15%;
    border-radius: 10px;
    background: #FFFFFF;
    display: flex;
    padding: 20px;
    .left{
      width: 55px;
      img{
        width: 50px;
        height: 50px;
        position: relative;
        bottom: 7px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: 400;
      .label{
        font-size: 18px;
      }
      .val{
        font-size: 24px;
      }
      .yesterday-val{
        font-weight: 200;
        font-size: 18px;
        color: #9A9AB1;
      }
    }
  }
}
.left_menu{
  width: 13%;
  background: #ffffff;
  .item{
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 50px;
    cursor: pointer;
    img{
      width: 40px;
      height: 40px;
    }
  }
}
.right_content{
  width: 100%;
  padding: 1%;

}



$box_shadow: 0px 3px 10px #409eff;
.el-icon-more{
  position: absolute;
  z-index: 99999;
  font-size: 20px;
  left: 50%;
  cursor: pointer;
}
header {
  transition: all 0.5s;
}
.head-enter,
.head-leave-to {
  transform: translateY(-100%);
}
.head-enter-to,
.head-leave {
  transform: translateY(0);
}
.main-container {
  background: #fff !important;
}
.free {
  background-color: orange;
}
.messageText {
  width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.avatar img {
  width: 40px !important;
  border-radius: 50%;
}
.modification {
  display: flex;
  justify-content: center;
  > div {
    margin-top: 100px;
    width: 300px;
    box-shadow: $box_shadow;
    border-radius: 5px;
    padding: 60px;
  }
  .el-form-item {
    margin-bottom: 40px !important;
  }
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  ::v-deep .el-form-item__content {
    text-align: center;
  }
  .login {
    background: rgb(194, 176, 223);
    background: linear-gradient(
      270deg,
      rgba(194, 176, 223, 1) 0%,
      rgb(84, 105, 209) 45%
    );
    border-radius: 10px;
    color: #fff;
    height: 40px;
    font-weight: 600;
    padding: 0 20px;
  }
}

#wx {
  position: absolute;
  width: 120px;
  top: 0;
  left: -125px;
}
iframe {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.project {
  font-weight: 600;
  flex-wrap: wrap;
  .title{
    color: #1984FC;
    font-size: 16px;
    margin: 0 0 5px 0;
  }
  .end {
    button {
      background-color: #1984fc;
      color: #fff;
      border-radius: 20px;
      padding: 5px 15px;
    }
    margin-top: 10px;
  }
  img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  span {
    color: #666;
  }
  > div {
    width: 20%;
    height: 275px;
    box-shadow: $box_shadow;
    border-radius: 5px;
    > p {
      color: #1984FC;
      margin: 10px;
      font-size: 16px;
    }
  }
}
.info {
  p {
    line-height: 40px;
    font-weight: 600;
    span {
      margin: 0 40px 0 10px;
      color: #1984fc;
    }
  }
  .top {
    margin-bottom: 50px;
    > div {
      font-weight: 600;
      color: #1984fc;
      font-size: 16px;
    }
    button {
      color: #1984fc;
      background-color: #bfd4f8;
      border-radius: 10px;
      padding: 5px 15px;
      border: 1px solid #1984fc;
    }
  }
}
.message {
  .price-info{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 240px;
    align-items: baseline;
    .label{
      font-size: 20px;
    }
    .price{
      font-size: 25px;
      font-weight: bold;
      color: #333333;
    }
    .chongzhi,.jiaoyilog{
      width: 140px;
    }
  }
  .el-pagination {
    text-align: center;
    ::v-deep .number {
      background-color: #fff;
    }
    ::v-deep button {
      background-color: #fff;
    }
  }
  .item {
    line-height: 34px;
  }
  .top {
    margin-bottom: 10px;
    > div {
      font-weight: 600;
      color: #1984fc;
      font-size: 16px;
    }
  }
}
.info{
  width: 58%;
  height: 280px;
  border-radius: 5px;
  box-shadow: $box_shadow;
}
.message {
  background-image: url('../assets/imgs/4258.png');
  background-size: 100% 100%;
  padding: 20px;
  width: 36%;
  height: 240px;
  border-radius: 10px;
  box-shadow: $box_shadow;
  .price-info{
    margin-left: 20px;
  }
}
.title {
  margin: 30px 0;
  img {
    width: 30px;
  }
  h3 {
    color: #1984fc;
    font-size: 16px;
  }
}
.active {
  color: #1984fc;
}
header {
  border-bottom: 1px solid #ddd;
  height: 50px;
  padding: 0 20px 0 0;
  img {
    width: 60px;
  }
  .titleTop {
    width: 240px;
  }
  .leftShu {
    margin: 0 10px;
    width: 2px;
    height: 20px;
    background: #000;
  }
  ul {
    font-weight: 600;
    font-size: 16px;
    li {
      cursor: pointer;
      padding: 0 5px;
      margin: 0 30px;
    }
  }
}

#gorecharge {
  background: rgb(194, 176, 223);
  background: linear-gradient(
    270deg,
    rgba(194, 176, 223, 1) 0%,
    rgb(84, 105, 209) 45%
  );
  border-radius: 10px;
  color: #fff;
  height: 40px;
  font-weight: 600;
  padding: 0 20px;
  margin: 30px 0 0 110px;
}
::v-deep .el-dialog {
  width: 60% !important;
}
.box {
  height: 420px;
  margin: 0 auto;
  border: 1px solid #ddd;
}

.Boxtop {
  line-height: 60px;
  background-color: #f2f2f2;
  color: #000;
  padding-left: 30px;
}

.type,
.sum {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 30px;
}

.type > div {
  position: relative;
  width: 150px;
  height: 50px;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.icon {
  vertical-align: middle;
}

.true,
.sum div {
  position: absolute;
  background-color: #f84632;
  width: 30px;
  height: 30px;
  transform: rotate(45deg);
  bottom: -15px;
  right: -15px;
}

.sum div {
  display: none;
}

.true span {
  position: absolute;
  color: #fff;
  transform: rotate(-45deg);
  bottom: 6px;
  left: 0;
}

.sum div span {
  position: absolute;
  color: #fff;
  transform: rotate(-45deg);
  bottom: -4px;
  left: -1px;
}

.sum ul {
  display: flex;
  align-items: center;
  padding-left: 30px;
  flex-wrap: wrap;
}

.sum ul li {
  position: relative;
  width: 90px;
  line-height: 40px;
  border: 1px solid #ddd;
  cursor: pointer;
  margin-right: 30px;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
}

.li_active {
  display: block !important;
}

.custom {
  border: 1px solid #ddd;
  margin-top: 20px;
  height: 40px;
  margin-left: 115px;
  border-radius: 5px;
  padding-left: 10px;
  display: block;
  width: 200px;
}
</style>
